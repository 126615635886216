import CameraIcon from "@heroicons/react/outline/CameraIcon";
import { Carousel } from "antd";
import styled from "styled-components";

import { COLORS, DEVICE } from "styles";

interface CameraIconProps {
  color: string;
}

export const PODIcon = styled(CameraIcon)<CameraIconProps>`
  width: 24px;
  color: ${({ color }) => color};
  margin: 0 8px 0 0;
`;

export const PODCarouselWrapper = styled.div`
  max-width: 97vw;

  @media ${DEVICE.lg} {
    flex: none;
    width: 360px;
  }
`;

export const PODCarousel = styled(Carousel)`
  &:hover,
  &:focus {
    cursor: grab;
  }

  > .slick-dots-bottom {
    bottom: 10px;
  }

  > .slick-dots li button {
    background: ${COLORS.GREY};
    opacity: 0.5;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  > .slick-dots li.slick-active button {
    background: ${COLORS.GREEN_PRIMARY};
  }

  > .slick-prev,
  > .slick-prev:focus {
    font-size: 1.5em;
    left: 10px;
    z-index: 2;
    color: ${COLORS.GREY};
  }

  > .slick-next,
  > .slick-next:focus {
    font-size: 1.5em;
    right: 10px;
    z-index: 2;
    color: ${COLORS.GREY};
  }

  > .slick-prev:hover,
  > .slick-next:hover {
    color: ${COLORS.GREEN_PRIMARY};
  }
`;

export const ImageWrapper = styled.div`
  width: 95%;
`;

export const Image = styled.img`
  margin: 0 auto 30px auto;
  padding-bottom: 10px;
  max-width: 80%;
  max-height: 300px;
`;
