import { useQuery } from "react-query";

import { CampaignType, useGetRedirectParams } from "./useGetRedirectParams";

import { fetchAppointment, Appointment } from "hooks/api/fetchAppointment";
import { notification } from "antd";

export const GOBOLT_REVIEW_URL =
  process.env.REACT_APP_GOBOLT_REVIEW_URL || "https://www.trustpilot.com/evaluate/gobolt.com";
export const GOBOLT_SUPPORT_BASE_URL = process.env.REACT_APP_GOBOLT_SUPPORT_BASE_URL || "https://help.gobolt.com";
export const GOBOLT_FEEDBACK_TICKET_FORM_ID = process.env.REACT_APP_GOBOLT_FEEDBACK_FORM_ID || "4168833";

export const useRedirectUrl = () => {
  const { token, utm_campaign, email, locale } = useGetRedirectParams();
  const defaultRedirectUrl = {
    thumbs_up: GOBOLT_REVIEW_URL,
    thumbs_down: buildSupportURL(email, locale),
  }[utm_campaign || "thumbs_up"];

  const { data, isLoading } = useQuery({
    queryKey: "appointment",
    queryFn: () => fetchAppointment(token),
    select: (data: Appointment) => selectExternalRedirectUrl(data, utm_campaign),
    enabled: !!token,
    onError: (e: string) => {
      notification.error({
        message: "Error",
        description: e,
      });
    },
  });

  if (isLoading) return;

  return data || defaultRedirectUrl;
};

function selectExternalRedirectUrl(appointment: Appointment, utm_campaign?: CampaignType) {
  const organizationUrls = appointment.organization_urls;

  const redirectUrl = {
    thumbs_up: organizationUrls?.review,
    thumbs_down: organizationUrls?.support,
    default: undefined,
  }[utm_campaign || "default"];

  return redirectUrl;
}

function buildSupportURL(_email?: string, _locale?: string) {
  //TODO: update link for other locales once it's available
  return `${GOBOLT_SUPPORT_BASE_URL}/en/articles/${GOBOLT_FEEDBACK_TICKET_FORM_ID}`;
}
