import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { colorState } from "recoil/colourPicker/atoms";

import { MenuItem } from "../MenuItem";

export const ProofOfDelivery: FC = () => {
  const color = useRecoilValue(colorState);
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const { t } = useTranslation();
  const podImages = appointment?.verification_images?.map((image) => image.url);
  const shouldShowPODMenu = !!podImages && podImages.length > 0;

  return shouldShowPODMenu ? (
    <MenuItem title={t("Proof of Delivery")} renderIcon={() => <S.PODIcon color={color} />}>
      <S.PODCarouselWrapper>
        <S.PODCarousel arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
          {podImages.map((url, index) => (
            <S.ImageWrapper key={`img wrapper - ${url}`}>
              <S.Image key={url} src={url} alt={`carousel-${index}`} />
            </S.ImageWrapper>
          ))}
        </S.PODCarousel>
      </S.PODCarouselWrapper>
    </MenuItem>
  ) : null;
};
