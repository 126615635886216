import { FC } from "react";

import { Space } from "antd";

import * as S from "./styles";

import { LABEL_DELIVERED, LABEL_OUT_FOR_DELIVERY, LABEL_LOST, LABEL_RETURN_TO_SENDER } from "constants/labelStatuses";

import { buildReadableDate } from "../helpers";

import { PackageTrackingInfo } from "interface/packageTrackingInfo";

interface EstimatedDeliveryProps {
  trackingInfo: PackageTrackingInfo;
}

export const EstimatedDeliveryInfo: FC<EstimatedDeliveryProps> = ({ trackingInfo }) => {
  const { estimated_delivery_date, label_status } = trackingInfo;

  const shouldHideDate =
    !estimated_delivery_date ||
    label_status === LABEL_OUT_FOR_DELIVERY ||
    label_status === LABEL_DELIVERED ||
    label_status === LABEL_LOST ||
    label_status === LABEL_RETURN_TO_SENDER;

  if (shouldHideDate) return null;

  const dateStr = new Date(estimated_delivery_date);
  const isDelayedDelivery = new Date(estimated_delivery_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);

  if (isDelayedDelivery) return null;

  return (
    <S.Container>
      <Space direction="vertical" align="start" size={40}>
        <S.MainText>{`Estimated Delivery Date: ${buildReadableDate(dateStr)}`}</S.MainText>
      </Space>
    </S.Container>
  );
};
